<template>
    <div>
        <div>
            <CAlert
                color="danger"
                :show.sync="checkError"
                class="alert-dismissible"
            >
                {{ this.errorMessage }}
            </CAlert>
        </div>

        <div class="row">
            <div class="col-md-12">
                <CCard>
                    <CCardBody>
                        <CForm novalidate>
                            <div class="form-group d-flex mb-0">
                                <label
                                    class="
                                        asterisk
                                        col-md-6 col-lg-6 col-xl-4
                                        mt-2
                                    "
                                >
                                    First Name
                                </label>
                                <div class="col-xl-8 col-lg-6 col-md-6">
                                    <CInput
                                        v-model="profile.first_name"
                                        required
                                        was-validated
                                    />
                                    <template
                                        v-if="
                                            !profile.first_name &&
                                            buttonClick == true
                                        "
                                    >
                                        <p class="errorMessage">
                                            First name is required
                                        </p>
                                    </template>
                                </div>
                            </div>

                            <div class="form-group d-flex mb-0">
                                <label class="col-md-6 col-lg-6 col-xl-4 mt-2">
                                    Last Name
                                </label>
                                <div class="col-xl-8 col-lg-6 col-md-6">
                                    <CInput v-model="profile.last_name" />
                                </div>
                            </div>

                            <div class="form-group d-flex mb-0">
                                <label
                                    class="
                                        asterisk
                                        col-md-6 col-lg-6 col-xl-4
                                        mt-2
                                    "
                                >
                                    Email
                                </label>
                                <div class="col-xl-8 col-lg-6 col-md-6">
                                    <CInput
                                        value=""
                                        required
                                        was-validated
                                        v-model="profile.email"
                                    />
                                    <template
                                        v-if="
                                            !profile.email &&
                                            buttonClick == true
                                        "
                                    >
                                        <p class="errorMessage">
                                            Email is required
                                        </p>
                                    </template>
                                </div>
                            </div>
                            <div class="form-group d-flex mb-0">
                                <label
                                    class="
                                        asterisk
                                        col-md-6 col-lg-6 col-xl-4
                                        mt-2
                                    "
                                >
                                    Password
                                </label>
                                <div class="col-xl-8 col-lg-6 col-md-6">
                                    <CInput
                                        id="password"
                                        v-model="profile.password"
                                        type="password"
                                        required
                                        :is-valid="pwValidator"
                                        invalid-feedback="Your password must be of length >8 but <=10 and 
                                        must have one upper case character, one special character (@, $, _, !) and a digit."
                                    >
                                    <template #append>
                                        <CButton size="sm" class="ml-1" color="secondary" @click="showPassword()">
                                            <div v-if="!flag">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                                                    <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                                                </svg>
                                                
                                                <!-- <CIcon size="sm" name="cil-lock-locked"/> -->
                                            </div>
                                            <div v-if="flag">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                                </svg>
                                                <!-- <CIcon size="sm" name="cil-lock-unlocked"/> -->
                                            </div>
                                        </CButton>
                                    </template>
                                
                                    </CInput>

                                    <template
                                        v-if="
                                            !profile.password &&
                                            buttonClick == true
                                        "
                                    >
                                        <p class="errorMessage">
                                            Password is required
                                        </p>
                                    </template>
                                </div>
                            </div>

                            <div class="form-group d-flex mb-0">
                                <label class="col-md-6 col-lg-6 col-xl-4 mt-2">
                                    Contact
                                </label>
                                <div class="col-xl-8 col-lg-6 col-md-6">
                                    <CInput v-model="profile.phone" />
                                </div>
                            </div>

                            <div class="mb-4 d-flex align-items-center">
                                <CCol
                                    tag="label"
                                    class="
                                        col-xl-4
                                        col-6
                                        col-lg-5
                                        col-md-6
                                        col-sm-12
                                        mb-0
                                    "
                                >
                                    Status
                                </CCol>
                                <CCol
                                    class="
                                        d-flex
                                        col-8
                                        col-md-8
                                        col-lg-9
                                        col-xl-10
                                        col-sm-12
                                        align-items-center
                                    "
                                >
                                    <h6 class="mb-0">Inactive</h6>
                                    <CSwitch
                                        class="mt-1 ml-2 mr-2"
                                        color="primary"
                                        shape="pill"
                                        v-model="profile.is_active"
                                        @update:checked="changeStatus()"
                                    />
                                    <h6 class="mb-0">Active</h6>
                                </CCol>
                            </div>
                        </CForm>

                        <div class="form-group form-actions float-right">
                            <router-link to="/admin">
                                <CButton type="submit" size="sm" color="danger">
                                    Cancel
                                </CButton>
                            </router-link>
                            <CButton
                                type="submit"
                                size="sm"
                                color="primary"
                                class="ml-3"
                                @click="errorCheck()"
                            >
                                Add
                            </CButton>
                        </div>
                    </CCardBody>
                </CCard>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

const URL = axios.defaults.baseURL;
const registerAdminUserUrl = URL + `${"profile/register/adminuser/"}`;

export default {
    name: "AdminNewAccount",
    data() {
        return {
            buttonClick: false,
            profile: {
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                city: "",
                state: "",
                country: "",
                phone: "",
                is_active: "",
            },
            errorMessage: "",
            checkError: false,
            flag: 0,

        };
    },
    methods: {
        changeStatus() {
            this.profile.is_active = !this.profile.is_active;
        },

        showPassword(){
            const passwordField = document.querySelector('#password');
            if(this.flag==0)
                this.flag=1;
            else
                this.flag=0;
             
             if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text')
                else passwordField.setAttribute('type', 'password')
        },


        errorCheck() {
            this.buttonClick = true;

            if (
                this.profile.first_name != "" &&
                this.profile.email != "" &&
                this.profile.password != ""
            ) {
                this.buttonClick = false;
                this.createUser();
            }
        },

        createUser() {
            this.axios
                .post(registerAdminUserUrl, this.profile, {})
                .then((response) => {
                    this.$router.push("/admin");
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        // console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },

        pwValidator(val){

            if(val.length<8 || val.length>10){
                 return false
            }

            if(!val.includes("@") && !val.includes("_") && !val.includes("!") && !val.includes("."))
                return false;

            if(!val.match(/\d+/g)){
                return false;
            }

            var i= 0;
            var flag= false;
    
            while(i< val.length){
                var character= val.charAt(i);
                if(character == character.toUpperCase() && character.match(/^[A-Z]+$/)){
                    flag= true;
                }
                    
                i++;
            }

            return flag; 
        },
    },

    watch:{
        checkError(newValue){
            if (newValue)
              setTimeout(() => this.checkError = false, 2000)  
        }
    },
};
</script>